<template>
    <Link :link="teaser.link || {}" class="product-details-link">
        <div class="home-teaser container"></div>
    </Link>
</template>

<script>
import NettoPrice from '@/themes/nettovorteilswelt/components/NettoPrice';

export default {
    name: 'NettoHomeTeaser',
    components: {
        NettoPrice
    },
    props: {
        teaser: Object
    },
    computed: {
        hasPrice() {
            return (
                !this.$store.state.globalMeta.multivoucherShop &&
                (this.teaser.minimumPrice ||
                    this.teaser.minimumPerPersonPrice ||
                    this.teaser.minimumPerPersonPerNightPrice)
            );
        }
    }
};
</script>

<style scoped lang="scss">
@use '@/styles/variables';
@use 'sass:map';
@use '@/themes/nettovorteilswelt/styles/_variables' as theme;

.product-details-link {
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
    text-decoration: none;
    bottom: 0;

    .home-teaser {
        display: flex;
        justify-content: flex-end;
        padding-top: 40px;
        padding-bottom: 40px;

        .teaser-text {
            width: map-get(variables.$grid, 'tile-width-lg');
            z-index: 3;
            background: hsl(0, 0%, 100%);
            padding: 25px;
        }
    }
}
</style>
